export default [
  {
    url: '/',
    name: "Dashboard",
    icon: "HomeIcon",
    i18n: "Dashboard",
    slug: "dashboard"
  },
  {
    url: null,
    name: "Orders",
    icon: "ShoppingCartIcon",
    i18n: "Orders",
    submenu: [
      {
        url: '/orders?status=draft',
        name: "Draft",
        slug: "orders",
        i18n: "Draft",
      },
      {
        url: '/orders?status=pending',
        name: "Pending",
        slug: "orders",
        i18n: "Pending",
      },
      {
        url: '/orders?status=delivered',
        name: "Delivered",
        slug: "orders",
        i18n: "Delivered",
      },
      {
        url: '/orders?status=completed',
        name: "Completed",
        slug: "orders",
        i18n: "Completed",
      },
      {
        url: '/orders?status=refunded',
        name: "Refunded",
        slug: "orders",
        i18n: "Refunded",
      },
    ]
  },
  {
    url: null,
    name: "Reviews",
    icon: "AwardIcon",
    i18n: "Reviews",
    submenu: [
      {
        url: '/reviews?is_real=1',
        name: "Real",
        slug: "reviews",
        i18n: "Real",
      },
      {
        url: '/reviews?is_real=0',
        name: "Flip",
        slug: "reviews",
        i18n: "Flip",
      },
    ]
  },
  {
    url: '/users',
    name: "Users",
    icon: "UserIcon",
    i18n: "Users",
    slug: "user-list",
    submenu: [
      {
        url: '/users/create',
        name: "Add New",
        slug: "user-create",
        i18n: "AddNew",
      },
      {
        url: '/users?type=buyer',
        name: "Buyers",
        slug: "user-list",
        i18n: "Buyers",
      },
      {
        url: '/users?type=worker',
        name: "Workers",
        slug: "user-list",
        i18n: "Workers",
      },
      {
        url: '/users?type=admin',
        name: "Admin",
        slug: "user-list",
        i18n: "Admin",
      },
    ]
  },
  {
    url: null,
    name: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    submenu: [
      {
        url: '/settings/coupons',
        name: "Coupons",
        slug: "settting-coupon",
        i18n: "Coupon",
      },
    ]
  },
  {
    url: null,
    name: "Configure",
    icon: "DatabaseIcon",
    i18n: "Configure",
    submenu: [
      {
        url: '/configure/skills',
        name: "Skills",
        slug: "configure-skills",
        i18n: "Skills",
      },
      {
        url: '/configure/languages',
        name: "Languages",
        slug: "configure-languages",
        i18n: "Languages",
      },
    ]
  },
  {
    url: "/payments",
    name: "Payments",
    slug: "payments",
    icon: "ArchiveIcon",
    i18n: "Payments",
  }
]

