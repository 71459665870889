
<template>
<div class="relative">
  <div class="vx-navbar-wrapper navbar-full p-0">
    <vs-navbar class="navbar-custom navbar-skelton" :class="navbarClasses"  :style="navbarStyle" :color="navbarColor">

      <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" />

      <router-link to="/" target="_blank" class="vx-logo cursor-pointer mx-auto flex items-center">
        <span class="vx-logo-text text-primary">Desgin Kago</span>
      </router-link>

      <i18n />

      <chat />

      <search-bar />

      <notification-menu />

      <profile-drop-down />

    </vs-navbar>
  </div>
</div>
</template>

<script>
import Bookmarks            from "./components/Bookmarks.vue"
import I18n                 from "./components/I18n.vue"
import SearchBar            from "./components/SearchBar.vue"
import NotificationMenu     from "./components/NotificationMenu.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"
import Chat                 from "./components/Chat.vue"

export default {
  name: "the-navbar-horizontal",
  props: {
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    Bookmarks,
    I18n,
    SearchBar,
    NotificationMenu,
    ProfileDropDown,
    Chat,
  },
  computed: {
    navbarColor() {
      let color = "#fff"
      if (this.navbarType === "sticky") color = "#f7f7f7"
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = "#f7f7f7"
        }
      }
      this.isThemedark === "dark" ? color === "#fff" ? color = "#10163a" : color = "#262c49" : null
      return color
    },
    isThemedark()          { return this.$store.state.theme                                                                       },
    navbarStyle()          { return this.navbarType === "static" ? {transition: "all .25s ease-in-out"} : {}                      },
    navbarClasses()        { return this.scrollY > 5 && this.navbarType === "static" ? null : "d-theme-dark-light-bg shadow-none" },
    scrollY()              { return this.$store.state.scrollY                                                                     },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth                                                        },
    windowWidth()          { return this.$store.state.windowWidth                                                                 },
  }
}

</script>

<style type="text/css">
.target-message .feather-icon-badge{
  background: rgba(var(--vs-warning), 1) !important
}
</style>
